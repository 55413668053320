import { JobCentersModule } from './system/Panel/administrative/humanresources/job-centers/job-centers.module';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { LoginGuard } from './system/core/guards/validate-auth.guard';
import { ValidateTokenGuard } from './system/core/guards/validate-token.guard';
import {ProvidersModule} from "./system/Panel/administrative/inventory/providers/providers.module";

const routes: Routes = [
    { path: 'auth',
    canActivate:[LoginGuard],
    canLoad:[LoginGuard],
    loadChildren: () => import('./system/auth/auth.module').then(m => m.AuthModule)
},
{ path: '',
  canActivate:[ValidateTokenGuard],
  canLoad:[ValidateTokenGuard],
  loadChildren: () => import('./system/admin/admin.module').then(m => m.AdminModule)
},

 

  /**
   *   {
        path: '**',
        redirectTo: 'auth'
      },
   */
      {
        path: '',
        redirectTo: '/auth',
        pathMatch:'full'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes,{useHash:false})],
    exports: [RouterModule]
})
export class RoutingModule { }
