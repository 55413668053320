<ng-container *ngIf="type === 'p-autocomplete'">
<span class="w-100 p-fluid p-float-label">
  <p-autoComplete [multiple]="isMultiple" [formControl]="control" [showEmptyMessage]="true" [suggestions]="data"
    appendTo="body" (onChange)="enableChildrens()" (completeMethod)="filterMultiSelect($event)"
    (onSelect)="select($event)" [minLength]="1" [dropdown]="isDropdown" [field]="field"></p-autoComplete>
  <label for="autocomplete">{{label}}</label>
</span>
</ng-container>

<ng-container *ngIf="type === 'p-autocomplete-administrative'">
  <span class="w-100 p-fluid p-float-label">
    <p-autoComplete [multiple]="isMultiple" [formControl]="control" [showEmptyMessage]="true" [suggestions]="data"
      appendTo="body" (onChange)="enableChildrens()" (completeMethod)="filterMultiSelectAdministrative($event)"
      (onSelect)="select($event)" [minLength]="1" [dropdown]="isDropdown" [field]="field"></p-autoComplete>
    <label for="autocomplete">{{label}}</label>
  </span>
  </ng-container>

  <ng-container *ngIf="type === 'pest-control'">
    <span class="w-100 p-fluid p-float-label">
      <p-autoComplete [multiple]="isMultiple" [formControl]="control" [showEmptyMessage]="true" [suggestions]="data"
        appendTo="body" (onChange)="enableChildrens()" (completeMethod)="filterMultiSelectPestControl($event)"
        (onSelect)="select($event)" [minLength]="1" [dropdown]="isDropdown" [field]="field"></p-autoComplete>
      <label for="autocomplete">{{label}}</label>
    </span>
    </ng-container>

    <ng-container *ngIf="type === 'inventories'">
      <span class="w-100 p-fluid p-float-label">
        <p-autoComplete [multiple]="isMultiple" [formControl]="control" [showEmptyMessage]="true" [suggestions]="data"
          appendTo="body" (onChange)="enableChildrens()" (completeMethod)="filterMultiSelectInventories($event)"
          (onSelect)="select($event)" [minLength]="1" [dropdown]="isDropdown" [field]="field"></p-autoComplete>
        <label for="autocomplete">{{label}}</label>
      </span>
      </ng-container>

      <!--caso especial cuando es ngmodel-->
      <ng-container *ngIf="type === 'pest-control-ngModel'">
        <span class="w-100 p-fluid p-float-label">
          <p-autoComplete [multiple]="isMultiple"  [(ngModel)]="area" [showEmptyMessage]="true" [suggestions]="data"
            appendTo="body" (onChange)="enableChildrens()" (completeMethod)="filterMultiSelectPestControl($event)"
            (onSelect)="select($event)" [minLength]="1" [dropdown]="isDropdown" [field]="field"></p-autoComplete>
          <label for="autocomplete">{{label}}</label>
        </span>
        </ng-container>


              <!--caso generic-->
      <ng-container *ngIf="type === 'GENERIC'">
        <span class="w-100 p-fluid p-float-label">
          <p-autoComplete [multiple]="isMultiple"  [formControl]="control" [showEmptyMessage]="true" [suggestions]="data"
            appendTo="body" (onChange)="enableChildrens()" (completeMethod)="filterMultiSelectGeneric($event)"
            (onSelect)="select($event)" [minLength]="1" [dropdown]="isDropdown" [field]="field"></p-autoComplete>
          <label for="autocomplete">{{label}}</label>
        </span>
    </ng-container>

    
<ng-container *ngIf="type === 'select-generic'">
  <span class="w-100 p-fluid p-float-label">
    <p-autoComplete [multiple]="isMultiple" [formControl]="control" [showEmptyMessage]="true" [suggestions]="data"
      appendTo="body" (onChange)="enableChildrens()" (completeMethod)="filterSelectCatalog($event)"
      (onSelect)="select($event)" [minLength]="1" [dropdown]="isDropdown" [field]="field"></p-autoComplete>
    <label for="autocomplete">{{label}}</label>
  </span>
  </ng-container>