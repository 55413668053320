import { DateService } from '../../../services/date.service';
import { Component, Input } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { CatalogsEnum } from 'src/app/system/core/enums/catalogs.model';
import { AreasControlService } from 'src/app/system/services/areasControlService';
import { CatalogService } from 'src/app/system/services/catalogs.service';
import { MessagesService } from 'src/app/system/services/message.service';
import { UserService } from 'src/app/system/services/user.service';
import { AddUpdateAreaComponent } from './components/add-update-area/add-update-area.component';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-table-areas',
  templateUrl: './table-areas.component.html',
  styleUrls: ['./table-areas.component.scss']
})
export class TableAreasComponent {
  @Input() idRegister:string = "";
  loading: boolean = false;
  dataForm!: FormGroup;
  dataItems!: any[];
  Catalog = CatalogsEnum;
  filteredZones:any = [];
  filteredCategoryAreas:any = [];
  constructor(private DateService:DateService, private formBuilder:FormBuilder, private MessagesService:MessagesService, private DialogService:DialogService, private  AreasControlService:AreasControlService,private UserService:UserService, private CatalogService:CatalogService){
  }
  ngOnInit(): void {
    this.loadDataForm();
    // this.updateEventSharedService();
     this.loadDataAreasControl();

   }

   loadDataForm():void {
    this.dataForm = this.formBuilder.group({
      page_size : 10,
      first: 0,
      page: 0,
      rows: 10,
      search: '',
      totalRecords: 0,
      range_date: null,
      initial_date: null,
      final_date: null,
      zone: '',
      zone_search:'',
      perimeter: '',
      perimeter_search: '',
      area_category: '',
      area_category_search: '',
      job_center: this.UserService.JobCenter.id,
    });
  }





   loadDataAreasControl() : void {
  if(this.AreasControlService.OriginScreen === "BUSINESS-ACTIVITES"){
    this.getAreasByBusinessPest();
  }
  if(this.AreasControlService.OriginScreen === "CUSTOMERS"){
  this.getAreasByCustomerPest();

  }




  }
  getAreasByCustomerPest(){
    this.loading =  true;
    this.AreasControlService.getAreasByCustomerPest(this.dataForm.value,this.idRegister).subscribe((response)=>{
      this.dataItems = response.items;
      this.dataForm.value.page = response.page;
      this.dataForm.value.totalRecords = response.total;
      this.loading = false;
      console.log(response);
      
    });
  }
   onPageChange(event: any) {
    this.dataForm.value.loading = true;
    this.dataForm.value.first = event.first;
    this.dataForm.value.page_size = event.rows;
    this.dataForm.value.pageCount = event.pageCount;
    this.dataForm.value.page = event.page + 1;
    this.loadDataAreasControl();
  }

    /**
     * Event Search Global
     * @param $event
     */
    getEventValue($event:any) : void {
      this.dataForm.value.search = $event.target.value;
      this.loading = true;
      this.loadDataAreasControl();
    }


  //CUSTOMERS PATH
  //https://api.dev.pestwareapp.com/administrative/pest-control/zones/?customer=74d0fe3b-f5e4-4ea5-ac79-36a0149f3bc3&job_center=7b0aeb50-9a03-46a0-b284-996f8455a6f1&page_size=10 customers

  //BUSINESS ACTIVITES PATH
  //https://api.dev.pestwareapp.com/administrative/business-pest/zones/?business_activity=1dd08594-779a-4a3f-8e2f-4baa43a448e9&job_center=7b0aeb50-9a03-46a0-b284-996f8455a6f1&page_size=10

  getAreasByBusinessPest(){
    this.loading =  true;
    this.AreasControlService.getaAreasControlByBusiness(this.dataForm.value,this.idRegister).subscribe((response)=>{
      this.dataItems = response.items;
      this.dataForm.value.page = response.page;
      this.dataForm.value.totalRecords = response.total;
      this.loading = false;
    });
  }



  AddUpdate(item: any) {
    const dialog = this.DialogService.open(AddUpdateAreaComponent, {
        header: "Areas",
        modal: true,
        width:"28rem",
        //height:"100%",
        closable: true,
        draggable: false,
        maximizable: false,
        resizable: true,
        closeOnEscape: true,
        dismissableMask: true,
        data: {
            item,
            idRegister:this.idRegister
        },
    });
    dialog.onClose.subscribe((res) => {
        if(res?.success){
            this.loadDataAreasControl();
        }

    });
  }

  deleteItem(item: any) {
    this.MessagesService.confirm("¿Esta seguro de eliminar el registro " + item.name + ' ?', () => {
      if (this.AreasControlService.OriginScreen === "BUSINESS-ACTIVITES") { this.deleteAreaBussines(item.id) }
       if (this.AreasControlService.OriginScreen === "CUSTOMERS") { this.deleteAreaCustomer(item.id) }

  });

  }

deleteAreaBussines(idArea:string){

    this.AreasControlService.deleteItemBusinessPestcontrol(CatalogsEnum.NESTING_AREAS,idArea).subscribe(res => {
        this.loadDataAreasControl();
    });

}


deleteAreaCustomer(idArea:string){
    this.AreasControlService.deleteItemCustomersPestcontrol(CatalogsEnum.NESTING_AREAS,idArea).subscribe(res => {
        this.loadDataAreasControl();
    });

}



  searchDate() {
    let initial_date!: string, final_date: string;
    let range_date: string = this.dataForm.value.range_date[1];
    if (range_date !== null) {
      initial_date = this.DateService.getFormatDataDate(this.dataForm.value.range_date[0]);
      final_date = this.DateService.getFormatDataDate(this.dataForm.value.range_date[1]);
      this.dataForm.patchValue({
        initial_date: initial_date,
        final_date: final_date
      });
      this.loadDataAreasControl();
    }
  }

   /**
   * Search Zones by Autocomplete
   */
   searchZone() {
    if (this.dataForm.value.zone_search !== '') {
      let zone = this.dataForm.value.zone_search.id;
      this.dataForm.get('zone')?.setValue(zone);
      this.loadDataAreasControl();
    }
  }

  filterZone(event: any) {

    let query = event.query;
    let data= {
      search:query
    }



    if (this.AreasControlService.OriginScreen === "BUSINESS-ACTIVITES"){
      this.AreasControlService.getZonesControlByBusiness(data,this.idRegister).subscribe((response)=>{
        this.filteredZones = response
      });
    }

    if (this.AreasControlService.OriginScreen === "CUSTOMERS"){
      this.AreasControlService.getZonesControlByCustomer(data,this.idRegister).subscribe((response)=>{
        this.filteredZones = response
      });
    }




  }

 /**
   * Search Area Categories by Autocomplete
   */
 searchAreaCategory() {
  if (this.dataForm.value.area_category_search !== '') {
    let area_category = this.dataForm.value.area_category_search.id;
    this.dataForm.get('area_category')?.setValue(area_category);
    this.loadDataAreasControl();
  }
}


  /**
   * In a real application, make a request to a remote url with the query and return filtered results.
   * @param event
   */
  filterCategoryArea(event: any) {
    let query = event.query;
    let data= {
      search:query
    }
    this.AreasControlService.getCategoryAreas(data).subscribe((response)=>{
      this.filteredCategoryAreas = response
    });




  }
  resetFilter(){
    this.dataForm.patchValue({
    page_size : 10,
    first: 0,
    page: 0,
    rows: 10,
    search: '',
    totalRecords: 0,
    range_date: null,
    initial_date: null,
    final_date: null,
    zone: '',
    zone_search:'',
    perimeter: '',
    perimeter_search: '',
    area_category: '',
    area_category_search: '',
    job_center: this.UserService.JobCenter.id,
  });
    this.loadDataAreasControl();
  }
}
