import { MessagesService } from 'src/app/system/services/message.service';
import { Component, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, Subscription } from 'rxjs';




import { LayoutService } from './service/app.layout.service';
import { UserService } from '../services/user.service';
import { StorageEnum } from '../core/enums/StorageEnum';



@Component({
    selector: 'app-layout',
    templateUrl: './app.layout.component.html'
})
export class AppLayoutComponent implements OnDestroy, OnInit {
    model: any[] = [];
    
  
    menuOutsideClickListener: any;

    menuScrollListener: any;

 

    constructor( private user:UserService, public layoutService: LayoutService, public renderer: Renderer2, public router: Router, public MessagesService:MessagesService) {
    

 


    }

    ngOnInit(): void {


        this.model = [
 

{
  label: 'Administrativo', icon: 'pi pi-briefcase',
items:[
  {
      label: 'Recursos humanos', icon: 'fa-solid fa-users-rays',
      items: [
          {
              label: 'Empleados', icon: 'fa-solid fa-users',
                 routerLink:   '/companie/job-center/administrative/employees'
          },

      ]
  },
  {
    label: 'Inventario', icon: 'fa-solid fa-warehouse',
    items: [
      {
        label: 'Proveedores', icon: 'fa-solid fa-building-user',
  
         routerLink:   '/companie/job-center/inventory/providers'
      },
      {
        label: 'Productos', icon: 'fa-solid fa-flask',
    
            routerLink:   '/companie/job-center/inventory/products'
      },
      {
        label: 'Ordenes de Compra', icon: 'fa-solid fa-file-invoice',
      
        
           routerLink:   '/companie/job-center/inventory/purchase-orders'
      },
      {
        label: 'Almacenes', icon: 'fa-solid fa-dolly',
  
        
           routerLink:   '/companie/job-center/inventory/warehouses'
      },
      {
        label: 'Traspasos', icon: 'fa-solid fa-exchange',
  
           routerLink:   '/companie/job-center/inventory/transfers'
      },
      {
        label: 'Entradas', icon: 'fa-solid fa-external-link-square',
     
            routerLink:   '/companie/job-center/inventory/entrys'
      },
      ]
  },
 /**
  *  {
    label: 'Gastos',
    icon: 'fa fa-money',
    routerLink:   '/companie/job-center/administrative/expenses'
  },
  {
    label: 'Remisiones',
    icon: 'fa fa-credit-card',
    routerLink: '/companie/job-center/administrative/referrals'
  },
  */

]

},

{
  label: 'Ajustes', icon: 'pi pi-cog',
items:[
  {
      label: 'Catalogos', icon: 'fa fa-list',
      items: [
          {
              label: 'Cotizaciones', icon: 'pi pi-wallet',
         
               routerLink: '/companie/job-center/catalogs/quotes'
          },
          {
            label: 'Servicios', icon: 'pi pi-server',
             routerLink: '/companie/job-center/catalogs/services'
        },
          {
            label: 'Monitoreo', icon: 'pi pi-desktop',  

               routerLink: '/companie/job-center/catalogs/monitoring'
          },
          {
            label: 'Productos', icon: 'pi pi-align-right',
          

             routerLink: '/companie/job-center/catalogs/products'
        },


          {
            label: 'Contabilidad',
            icon: 'pi pi-money-bill',
      
             routerLink: '/companie/job-center/catalogs/acounting'
          },

          {
            label: 'Calendario', icon: 'pi pi-calendar',
   
                routerLink: '/companie/job-center/catalogs/calendar'
        },

          {
            label: 'Recursos Humanos', icon: 'pi pi-users',
       
             routerLink: '/companie/job-center/catalogs/human-resources'
        },
          {
            label: 'Tickets', icon: 'pi pi-ticket',
            
             routerLink: '/companie/job-center/catalogs/tickets'
        },

          { label: 'Plan de acción', icon: ' pi pi-bolt',   

              
             routerLink: '/companie/job-center/catalogs/action-plan'
          },

          {
            label: 'Tareas', icon: 'pi pi pi-book',
       
                        
             routerLink: '/companie/job-center/catalogs/tasks'
        },



      ]
  },
 
]

},
        ];
            this.changeTheme( this.user.Company.web_color)
    }
  


    ngOnDestroy() {
  
 
  localStorage.removeItem(StorageEnum.JOB_CENTER);
        
    }


    navigate(item:any){

this.router.navigateByUrl(item.routerLink)
    }

    changeTheme(theme: string) {

              const themeLink = <HTMLLinkElement>document.getElementById('theme-link');
              const newHref = themeLink.getAttribute('href')!.replace(this.layoutService.config.theme, theme);
              this.replaceThemeLink(newHref, () => {
                  this.layoutService.config.theme = theme;
                  this.layoutService.onConfigUpdate();
              });
          }
        
          replaceThemeLink(href: string, onComplete: Function) {
              const id = 'theme-link';
              const themeLink = <HTMLLinkElement>document.getElementById(id);
              const cloneLinkElement = <HTMLLinkElement>themeLink.cloneNode(true);
        
              cloneLinkElement.setAttribute('href', href);
              cloneLinkElement.setAttribute('id', id + '-clone');
        
              themeLink.parentNode!.insertBefore(cloneLinkElement, themeLink.nextSibling);
        
              cloneLinkElement.addEventListener('load', () => {
                  themeLink.remove();
                  cloneLinkElement.setAttribute('id', id);
                  onComplete();
              });
          }
        




}

