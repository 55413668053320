

<div class="card flex justify-content-center">
  <p-tree
  class="w-full md:w-30rem"
  [value]="files"
  selectionMode="single" 
  [loading]="loading"

  (onNodeSelect)="nodeSelect($event)"

  />
</div>
