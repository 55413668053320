
<div class="row">
  <div class="col-12 ">

      <form (ngSubmit)="accept()" [formGroup]="Form">
    <span class="p-float-label p-fluid">
      <input formControlName="name" id="float-input" type="text" pInputText>
      <label for="float-input">Nombre</label>
    </span>

          <div class="d-flex justify-content-center mt-4">
              <button pButton pRipple type="submit" [disabled]="Form.invalid" label="Aceptar" class="p-button-raised me-2 btn-sm"></button>
              <button (click)="cancel()" pButton pRipple type="button" label="Cancelar" class="p-button-raised p-button-secondary btn-sm"></button>
          </div>
      </form>

</div>


</div>

