import { Component, Input, OnInit } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { CustomerService } from 'src/app/system/services/customer.service';
import { AddUpdateZoneComponent } from '../table-zones/components/add-update-zone/add-update-zone.component';
import { OptionsDiagramTreeComponent } from '../options-diagram-tree/options-diagram-tree.component';
import { AreasControlService } from 'src/app/system/services/areasControlService';

@Component({
  selector: 'app-tree-diagram',
  templateUrl: './tree-diagram.component.html',
  styleUrls: ['./tree-diagram.component.scss']
})
export class TreeDiagramComponent implements OnInit{

  constructor(private customerService: CustomerService, private AreasControlService:AreasControlService, private DialogService:DialogService){}

  ngOnInit(): void {
if(this.AreasControlService.OriginScreen === "CUSTOMERS"){
  this.getNestingAreaTree();
}

else{
this.getNestingAreaTreeBussiness();
}

  }

  @Input() idRegister: string = "";
  loading: boolean = false;
  files!: TreeNode[];
  files2 = []

  getNestingAreaTree(){
      this.loading = true;
      this.customerService.getNestingAreasTree(this.idRegister)
      .subscribe( data => {

        this.files = data.data
        this.loading = false
      })
  }


  getNestingAreaTreeBussiness(){
      this.loading = true;
      this.AreasControlService.getNestingAreasTree(this.idRegister)
      .subscribe( data => {

        this.files = data.data
        this.loading = false
      })
  }

nodeSelect(Node: any) {
const dialog = this.DialogService.open(OptionsDiagramTreeComponent, {
  header: "Selecciona una opción",
  modal: true,
  width: "25rem",
  //height:"100%",
  closable: true,
  draggable: false,
  maximizable: false,
  resizable: true,
  closeOnEscape: true,
  dismissableMask: true,
  data: {
    Node,
    idRegister: this.idRegister
  },
});
dialog.onClose.subscribe((res) => {

  if(this.AreasControlService.OriginScreen === "CUSTOMERS"){
    this.getNestingAreaTree();
  }

  else{
  this.getNestingAreaTreeBussiness();
  }

});


}




}
