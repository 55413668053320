

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, catchError, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../core/models/user.interface';
import { Employee, JobCenter } from '../core/models/employee.interface';
import { Company } from '../core/models/company.interface';
import { StorageService } from './local-storage.service';
import { StorageEnum } from '../core/enums/StorageEnum';


/**
 *Clase que se utiliza para el control de la informacion del usuario logueado
 **/

//const C_TOKEN_ADMINISTRADOR = environment.KEY_TOKEN;
const C_API_URL = environment.baseUrl;

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _user!: User;
  private _employee!: Employee;
  private _Company!: Company;
  private _JobCenter!: JobCenter;
  private _customer!: any;

  constructor(
    private http: HttpClient,
    private router: Router,
    private storage:StorageService
  ) {

  }
  logout() {
  //  localStorage.removeItem(C_TOKEN_ADMINISTRADOR);
    this.router.navigateByUrl('/login');
  }

  get Customer() {
    return this._customer;
  }
  set Customer(customer:any){
    this._customer = customer;
  }
  get User() {
    return this._user;
  }
set User(user:any){
  this._user = user;
}
  get Employee() {
    return this._employee;
  }
  set Employee(Employee:any){
    this._employee = Employee;
  }
  get Company() {
    return    this.storage.getItem(StorageEnum.COMPANY) ?? "";
  }
  set Company(Company:any){
    this._Company = Company;
  }


  get JobCenter():JobCenter {
    return this.storage.getItem(StorageEnum.JOB_CENTER) ?? "";
  }

  get Group() {
    return this.Employee.employee_group;
  }

  patchLogosCompany(formData: any, idCompany: string) {
    return this.http.patch<any>(`${C_API_URL}/companies/${idCompany}/`, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }
get profilePhoto(){
  return this.Employee.avatar ?? "assets/noImage.png";
}

  get LogoWeb(){
    let url = this.Company?.web_logo ? this.Company?.web_logo + '?' + Math.random() : "assets/noImage.png";
    
    return url;
  }

  set LogoWeb(LogoWeb:string){
    this.Company.web_logo = LogoWeb;
     localStorage.setItem('company',JSON.stringify(this.Company))
  }
    set document_stamp(document_stamp:string){
    this.Company.document_stamp = document_stamp;
  }
  set document_logo(document_logo:string){
    this.Company.document_logo = document_logo;
  }
  get document_logo(){
    let url = this.Company?.document_logo ? this.Company?.document_logo + '?' + Math.random() : "assets/noImage.png";
    return url;
  }
  get document_stamp  (){
    let url = this.Company?.document_stamp ? this.Company?.document_stamp + '?' + Math.random() : "assets/noImage.png";
  
    return url;
  }

}
