<form (ngSubmit)="accept()" [formGroup]="Form">

  <div class="grid formgrid  mt-3 ,b-4">
      <div class="col-12 mb-2  lg:col-12 lg:mb-0">
          <span class="w-100 p-fluid  p-input-icon-left">
              <i class="pi pi-pencil"></i>
              <input formControlName="name"  type="text" pInputText placeholder="Nombre"/>
          </span>
      </div>
      <div class="col-12 mb-2 lg:col-12 lg:mb-0">
        <span class="w-100 mt-4 p-fluid  p-float-label">
          <p-autoComplete
          formControlName="area_category"
          [suggestions]="filteredCategoryAreas"
          [showEmptyMessage]="true"
          appendTo="body"
          [dropdown]="true"
          field="name"
          [minLength]="1"
          (completeMethod)="filterCategoryArea($event)"

          class="w-full">
        </p-autoComplete>
          <label for="autocomplete">Tipo Area</label>
      </span>
      </div>

      <div *ngIf="!ZoneDiagramSelected" class="col-12 mb-2 lg:col-12 lg:mb-0">
        <span class="w-100 mt-4 p-fluid  p-float-label">
          <p-autoComplete
          formControlName="zone"
          [suggestions]="filteredZones"
          [showEmptyMessage]="true"
          appendTo="body"
          [dropdown]="true"
          field="name"
          [minLength]="1"
          (completeMethod)="filterZone($event)"

          class="w-full">
        </p-autoComplete>
          <label for="autocomplete">Zona</label>
      </span>
      </div>




  </div>

  <div class="d-flex mt-3 justify-content-center flex-wrap">
    <div class="me-3 field-checkbox">
        <p-checkbox name="disinfection" id="disinfection" formControlName="check_point" [binary]="true"></p-checkbox>
        <label for="disinfection">Punto control</label>
    </div>
    <div class="field-checkbox">
        <p-checkbox name="show_price"   id="show_price" formControlName="certificate" [binary]="true"></p-checkbox>
        <label for="show_price">Certificado</label>
    </div>
</div>
  <div class="col-12 mt-2 mb-2 lg:col-12 lg:mb-0 d-flex justify-content-center">
    <p-colorPicker formControlName="color" appendTo="body" ></p-colorPicker>
    <p class="ms-2 me-2">Color de area</p>
  </div>




  <div class="d-flex justify-content-center mt-4">
      <button pButton pRipple type="submit" [disabled]="Form.invalid" label="Aceptar" class="p-button-raised me-2 btn-sm"></button>
      <button (click)="cancel()" pButton pRipple type="button" label="Cancelar" class="p-button-raised p-button-secondary btn-sm"></button>
  </div>
</form>
