<div class="text-center">
    <i class="fa fa-circle-exclamation fa-5x text-danger"></i>
  </div>
  <div class="text-center">
    <p class="h1 mt-2 text-danger">ERROR</p>
  </div>
  <div class="text-center">
  <p class="fw-bold h5 mt-3">{{data}}</p>

  <button class="btn btn-danger mt-3" (click)="close()">Aceptar</button>

  </div>
