import { Component } from '@angular/core';
import { LoadingService } from 'src/app/system/services/loading.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent {
    isLoading$ = this.loading.isloading$;
    constructor(private loading:LoadingService) { }

    ngOnInit(): void {


    }
}
