
<div class="card">

    <p-fileUpload [id]="id" #fileUploader name="myFile[]"  [customUpload]="true" [accept]="TypeDocument"
         (onSelect)="onUpload($event)"
         [maxFileSize]="10000000" [showUploadButton]="true"
        [showCancelButton]="false" [auto]="true" class="w-full">
        <ng-template pTemplate="content">
            <div class="w-full py-3" style="cursor: copy" (click)="fileUploader.advancedFileInput.nativeElement.click()">

              <div *ngIf="!Document" class="h-full flex flex-column justify-content-center align-items-center">
                    <i class="pi pi-upload text-900 text-2xl mb-3"></i>
                    <span class="font-bold text-900 text-xl mb-3">{{Title}}</span>
                    <span class="font-medium text-600 text-md text-center">{{Description}}</span>
                </div>

                <div class="flex flex-wrap gap-5 text-center" *ngIf="Document && !isImage">
                    <div class="h-full relative w-7rem h-7rem border-3 border-transparent border-round hover:bg-primary transition-duration-100 cursor-auto"(mouseenter)="onImageMouseOver()" (mouseleave)="onImageMouseLeave()"  style="padding: 1px;">
                        <img src="https://play-lh.googleusercontent.com/X3tnd7tQVy2EIMWUticGw60a1Orp5Eq7OTRSfOzuKWKtqmfY6GhCQIUFDSapfy3HAw" alt="document" class="w-full h-full border-round shadow-2">
                        <span class="font-medium text-600 text-md text-center">{{Document.name | slice:0:20 }} ...</span>
                        <button (click)="removeImage($event)" [id]="Document.name" #buttonEl pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded btn-sm p-button-primary text-sm absolute justify-content-center align-items-center cursor-pointer" style="top: -10px; right: -10px; " ></button>
                    </div>
                </div>

            </div>
        </ng-template>
    </p-fileUpload>
</div>
