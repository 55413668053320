import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DialogService } from 'primeng/dynamicdialog';
import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { CatalogsEnum } from 'src/app/system/core/enums/catalogs.model';
import { AreasControlService } from 'src/app/system/services/areasControlService';
import { CatalogService } from 'src/app/system/services/catalogs.service';
import { DateService } from 'src/app/system/services/date.service';
import { MessagesService } from 'src/app/system/services/message.service';
import { UserService } from 'src/app/system/services/user.service';
import { AddUpdateCheckPointComponent } from './components/add-update-check-point/add-update-check-point.component';

@Component({
  selector: 'app-table-check-point',
  templateUrl: './table-check-point.component.html',
  styleUrls: ['./table-check-point.component.scss']
})
export class TableCheckPointComponent {
  @Input() idRegister:string = "";
  loading: boolean = false;
  dataForm!: FormGroup;
  dataItems!: any[];
  Catalog = CatalogsEnum;
  filteredAreas:any = [];
  filteredTypeStations:any = [];
  constructor(private DateService:DateService, private formBuilder:FormBuilder, private MessagesService:MessagesService, private DialogService:DialogService, private  AreasControlService:AreasControlService,private UserService:UserService, private CatalogService:CatalogService){
  }
  ngOnInit(): void {
    this.loadDataForm();
    // this.updateEventSharedService();
     this.loadDataCheckPointControl();

   }

   loadDataForm():void {
    this.dataForm = this.formBuilder.group({
      page_size : 10,
      first: 0,
      page: 0,
      rows: 10,
      search: '',
      totalRecords: 0,
      range_date: null,
      initial_date: null,
      final_date: null,
      station_type: '',
      station_type_search: '',
      nesting_area: '',
      nesting_area_search: '',

      job_center: this.UserService.JobCenter.id,
    });
  }





   loadDataCheckPointControl() : void {
    this.loading =  true;
    this.AreasControlService.getCheckPointControlByCustomer(this.dataForm.value,this.idRegister).subscribe((response)=>{
      this.dataItems = response.items;
      this.dataForm.value.page = response.page;
      this.dataForm.value.totalRecords = response.total;
      this.loading = false;
    });
  }



   onPageChange(event: any) {
    this.dataForm.value.loading = true;
    this.dataForm.value.first = event.first;
    this.dataForm.value.page_size = event.rows;
    this.dataForm.value.pageCount = event.pageCount;
    this.dataForm.value.page = event.page + 1;
    this.loadDataCheckPointControl();
  }

    /**
     * Event Search Global
     * @param $event
     */
    getEventValue($event:any) : void {
      this.dataForm.value.search = $event.target.value;
      this.loading = true;
      this.loadDataCheckPointControl();
    }


  //CUSTOMERS PATH
  //https://api.dev.pestwareapp.com/administrative/pest-control/zones/?customer=74d0fe3b-f5e4-4ea5-ac79-36a0149f3bc3&job_center=7b0aeb50-9a03-46a0-b284-996f8455a6f1&page_size=10 customers

  //BUSINESS ACTIVITES PATH
  //https://api.dev.pestwareapp.com/administrative/business-pest/zones/?business_activity=1dd08594-779a-4a3f-8e2f-4baa43a448e9&job_center=7b0aeb50-9a03-46a0-b284-996f8455a6f1&page_size=10

  getStationsByBusinessPest(){
    this.loading =  true;
    this.AreasControlService.getaStationsControlByBusiness(this.dataForm.value,this.idRegister).subscribe((response)=>{
      this.dataItems = response.items;
      this.dataForm.value.page = response.page;
      this.dataForm.value.totalRecords = response.total;
      this.loading = false;
    });
  }



  AddUpdate(item: any) {
    const dialog = this.DialogService.open(AddUpdateCheckPointComponent, {
        header: "Punto de control",
        modal: true,
        width:"52rem",
        //height:"100%",
        closable: true,
        draggable: false,
        maximizable: false,
        resizable: true,
        closeOnEscape: true,
        dismissableMask: true,
        data: {
            item,
            idRegister:this.idRegister
        },
    });
    dialog.onClose.subscribe((res) => {
        if(res?.success){
            this.loadDataCheckPointControl();
        }

    });
  }

  deleteItem(item: any) {
    this.MessagesService.confirm("¿Esta seguro de eliminar el registro " + item.name + ' ?', () => {

      this.AreasControlService.deleteItemCheckPoint(CatalogsEnum.AREA_STATIONS,item.id).subscribe(res => {
        this.MessagesService.ShowMessage(TYPE_TOAST.SUCCESS,"Correcto","Eliminado correctamente")
        this.loadDataCheckPointControl();
    });
    });
  }




}
