import { InjectTokenInterceptor } from './inject-token.interceptor';
import { ErrorInterceptor } from './error.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';


export const interceptorProviders =
[
     {provide: HTTP_INTERCEPTORS, useClass: InjectTokenInterceptor, multi:true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor,  multi:true},

]
