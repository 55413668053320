import { environment } from "src/environments/environment";

export enum CatalogsEnum {
  PLAGUES= "plagues",
  SERVICE_TYPE= "service-types",
  DISCOUNTS='discounts',
GROUPS='groups',
USERS='users',
  PRICE_LISTS = "price-lists",
  PRICE_LISTS_PLAGUE="price-lists-plague",
  BUSINESS_ACTIVITIES="business-activities",
  BUSINESS_ACTIVITIES_ICONS="business-activity-icons",
  ZONES="zones",
  NESTING_AREAS='nesting-areas',
  AREA_STATIONS='area-stations',
  CHECKPOINTS='checkpoints',
  TASK_TYPES='task-types',
  QUALIFICATIONS='qualifications',
  ROOT_CAUSES ='root-causes',
  TICKET_TYPES='ticket-types',
  MEDIA='media',
  JOB_TITLES='job-titles',
  EVENT_TYPES='event-types',
  PAYMENT_METHODS='payment-methods',
  PAYMENT_WAYS='payment-ways',
  VOUCHERS='vouchers',
  EXPENSES='expenses',
  CONCEPTS='concepts',
  TAXES='taxes',
  PRODUCT_PRESENTATIONS='product-presentations',
  PRODUCT_TYPES='product-types',
  PRODUCT_UNITS='product-units',
  DOSE_TYPES='dose-types',
  DOSE_TYPES_PRODUCTS='product-dose-types',
  TYPE_DOCUMENT_PRODUCTS='type-document-products',
  STATION_TYPES='station-types',
  STATION_ACTIVITIES='station-activities',
  AREA_ACTIVITY='activity_area',
  AREA_CONDITIONS='conditional-area',
  STATION_CONDITIONS='station-conditions',
  AREA_CATEGORIES='area-categories',
  STATION_TYPE_ACTIVITIES='station-type-activities',
  PRODUCTS='products',
  LIFE_CYCLE_PLAGUE_CATEGORIES='life-cycle-plague-categories',
  PLAGUE_CATEGORIES='plague-categories',
  EVIDENCE_TYPES='evidence-types',
  APPLICACTION_METHODS_CATEGORIES='application-methods-categories',
  INFESTATION_DEGREES='infestation-degrees',
  CLEANINGS_CATEGORIES='cleanings-categories',
  CANCELATION_REASONS = 'cancellation-reasons',
  APPLICATION_METHODS = 'application-methods',
  CLEANINGS = 'cleanings',
  JOB_CENTERS='job-centers',
  EMPLOYEES='employees',
  CUSTOMERS='customers',
  COMPANIES='companies',
  COUNTRIES='countries',
  QUOTES='quotes',
  ORIGIN_SOURCES='origin-sources',
  CUSTOM_DESCRIPTIONS='custom-descriptions',
  TICKETS='tickets',
  SUPPPLIERS='suppliers',
  WAREHOUSES='warehouse',
  WAREHOUSE_STOCK='warehouse-stock',
  TRANSFERS='transfers',
  PURCHASE_ORDER='purchase-orders'
}


export  const URL_CATALOGS = {
  COUNTRIES : `${environment.baseUrl}/catalogs/countries`,
  CUSTOMERS : `${environment.baseUrl}/customers`,
  CUSTOMERS_DROPDOWN : `${environment.baseUrl}/customer-drop`,
  PLAGUES : `${environment.baseUrl}/catalogs/plagues`,
  PRODUCTS_INVENTORY : `${environment.baseUrl}/inventories/products`,

}
