<div class="row">
    <div class="col-sm-12  col-md-2 bg-primary" style="height: 100vh;">
       <!-- 
        <div class="layout-menu-container">
            <ul class="layout-menu">
                <li  *ngFor="let item of model; let i = index;" [item]="item" [index]="i" [root]="true"></li>
            </ul>
        </div> 
       -->
        <ul class="list-none p-3 m-0 ">
            <li>
             
                <ul *ngFor="let title of model" class="list-none p-0 m-0 overflow-hidden layout-menu ">
                    <p class="mb-0 fw-bold mt-3">{{title.label}}</p>
                    <div class="mt-1" *ngFor="let item of title.items">
                        <li *ngIf="!item.items">

                            <a 
                            (click)="navigate(item)"
                            pRipple class="flex align-items-center cursor-pointer py-1 px-2 border-round  text-white  menu-item-side transition-duration-150 transition-colors p-ripple">
                                <i [class]=" item.icon " style="font-size: 18px; margin-right: 10px;"></i>
                                <span class="h5 mt-2">{{item.label}}</span>
                            </a>
                        </li>

                        <li *ngIf="item.items" >
                            <a
                                pRipple
                                pStyleClass="@next"
                                enterClass="hidden"
                                enterActiveClass="slidedown"
                                leaveToClass="hidden"
                                leaveActiveClass="slideup"
                                class="flex align-items-center cursor-pointer  py-1 px-2 border-round text-white menu-item-side transition-duration-150 transition-colors p-ripple"
                            >
                                <i class="pi pi-book mr-2 " style="font-size: 18px;"></i>
                                <span class="h5 mt-2">{{item.label}}</span>
                                <i class="pi pi-chevron-down ml-auto" style="font-size: 18px;"></i>
                            </a>
                            <ul  class="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                              
                                <li *ngFor="let subitem of item.items">
                                    <a (click)="navigate(subitem)" pRipple class="flex align-items-center cursor-pointer py-1 px-2 border-round text-white menu-item-side transition-duration-150 transition-colors p-ripple">
                                        <i [class]=" subitem.icon " style="font-size: 18px; margin-right: 10px;"></i>
                                        <span class="h5 mt-2">{{subitem.label}}</span>
                                    </a>
                                </li>
                            </ul>
                        </li>       
                    </div>
           
                
                </ul>
                
            </li>
        </ul>
  
    </div>
    <div class="col-sm-12  col-md-10">
        <router-outlet></router-outlet>
    </div>
</div>

