import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DialogService } from 'primeng/dynamicdialog';
import { CatalogsEnum } from 'src/app/system/core/enums/catalogs.model';
import { AreasControlService } from 'src/app/system/services/areasControlService';
import { CatalogService } from 'src/app/system/services/catalogs.service';
import { DateService } from 'src/app/system/services/date.service';
import { MessagesService } from 'src/app/system/services/message.service';
import { UserService } from 'src/app/system/services/user.service';
import { AddUpdateStationsComponent } from './components/add-update-stations/add-update-stations.component';
import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';

@Component({
  selector: 'app-table-stations',
  templateUrl: './table-stations.component.html',
  styleUrls: ['./table-stations.component.scss']
})
export class TableStationsComponent {
  @Input() idRegister:string = "";
  loading: boolean = false;
  dataForm!: FormGroup;
  dataItems!: any[];
  Catalog = CatalogsEnum;
  filteredAreas:any = [];
  filteredTypeStations:any = [];
  constructor(private DateService:DateService, private formBuilder:FormBuilder, private MessagesService:MessagesService, private DialogService:DialogService, private  AreasControlService:AreasControlService,private UserService:UserService, private CatalogService:CatalogService){
  }
  ngOnInit(): void {
    this.loadDataForm();
    // this.updateEventSharedService();
     this.loadDataStationsControl();

   }

   loadDataForm():void {
    this.dataForm = this.formBuilder.group({
      page_size : 10,
      first: 0,
      page: 0,
      rows: 10,
      search: '',
      totalRecords: 0,
      range_date: null,
      initial_date: null,
      final_date: null,
      station_type: '',
      station_type_search: '',
      nesting_area: '',
      nesting_area_search: '',

      job_center: this.UserService.JobCenter.id,
    });
  }





   loadDataStationsControl() : void {
  if(this.AreasControlService.OriginScreen === "BUSINESS-ACTIVITES"){
    this.getStationsByBusinessPest();
  }
  if(this.AreasControlService.OriginScreen === "CUSTOMERS"){
  this.getStationsByCustomer();
  }

  }


  getStationsByCustomer(){
    this.loading =  true;
    this.AreasControlService.getaStationsControlByCustomer(this.dataForm.value,this.idRegister).subscribe((response)=>{
      this.dataItems = response.items;
      this.dataForm.value.page = response.page;
      this.dataForm.value.totalRecords = response.total;
      this.loading = false;
    });
  }


   onPageChange(event: any) {
    this.dataForm.value.loading = true;
    this.dataForm.value.first = event.first;
    this.dataForm.value.page_size = event.rows;
    this.dataForm.value.pageCount = event.pageCount;
    this.dataForm.value.page = event.page + 1;
    this.loadDataStationsControl();
  }

    /**
     * Event Search Global
     * @param $event
     */
    getEventValue($event:any) : void {
      this.dataForm.value.search = $event.target.value;
      this.loading = true;
      this.loadDataStationsControl();
    }


  //CUSTOMERS PATH
  //https://api.dev.pestwareapp.com/administrative/pest-control/zones/?customer=74d0fe3b-f5e4-4ea5-ac79-36a0149f3bc3&job_center=7b0aeb50-9a03-46a0-b284-996f8455a6f1&page_size=10 customers

  //BUSINESS ACTIVITES PATH
  //https://api.dev.pestwareapp.com/administrative/business-pest/zones/?business_activity=1dd08594-779a-4a3f-8e2f-4baa43a448e9&job_center=7b0aeb50-9a03-46a0-b284-996f8455a6f1&page_size=10

  getStationsByBusinessPest(){
    this.loading =  true;
    this.AreasControlService.getaStationsControlByBusiness(this.dataForm.value,this.idRegister).subscribe((response)=>{
      this.dataItems = response.items;
      this.dataForm.value.page = response.page;
      this.dataForm.value.totalRecords = response.total;
      this.loading = false;
    });
  }



  AddUpdate(item: any) {
    const dialog = this.DialogService.open(AddUpdateStationsComponent, {
        header: "Estación",
        modal: true,
        width:"52rem",
        //height:"100%",
        closable: true,
        draggable: false,
        maximizable: false,
        resizable: true,
        closeOnEscape: true,
        dismissableMask: true,
        data: {
            item,
            idRegister:this.idRegister
        },
    });
    dialog.onClose.subscribe((res) => {
        if(res?.success){
            this.loadDataStationsControl();
        }

    });
  }

  deleteItem(item: any) {
    this.MessagesService.confirm("¿Esta seguro de eliminar el registro " + item.name + ' ?', () => {
      if (this.AreasControlService.OriginScreen === "BUSINESS-ACTIVITES"){
        this.deleteStationsBusiness(item.id);
      }
     else if (this.AreasControlService.OriginScreen === "CUSTOMERS"){
        this.deleteStationsCustomer(item.id);
      }
    });
  }

  deleteStationsBusiness(idStation:string){
    this.AreasControlService.deleteItemBusinessPestcontrol(CatalogsEnum.AREA_STATIONS,idStation).subscribe(res => {
      this.MessagesService.ShowMessage(TYPE_TOAST.SUCCESS,"Correcto","Eliminado correctamente")
      this.loadDataStationsControl();
  });
  }

  deleteStationsCustomer(idStation:string){
    this.AreasControlService.deleteItemCustomersPestcontrol(CatalogsEnum.AREA_STATIONS,idStation).subscribe(res => {
      this.MessagesService.ShowMessage(TYPE_TOAST.SUCCESS,"Correcto","Eliminado correctamente")
      this.loadDataStationsControl();
  });
}

  searchDate() {
    let initial_date!: string, final_date: string;
    let range_date: string = this.dataForm.value.range_date[1];
    if (range_date !== null) {
      initial_date = this.DateService.getFormatDataDate(this.dataForm.value.range_date[0]);
      final_date = this.DateService.getFormatDataDate(this.dataForm.value.range_date[1]);
      this.dataForm.patchValue({
        initial_date: initial_date,
        final_date: final_date
      });
      this.loadDataStationsControl();
    }
  }

   /**
   * Search Zones by Autocomplete
   */
   searchNestingArea() {
    if (this.dataForm.value.nesting_area_search !== '') {
      let nesting_area = this.dataForm.value.nesting_area_search.id;
      this.dataForm.get('nesting_area')?.setValue(nesting_area);
      this.loadDataStationsControl();
    }
  }

  filterArea(event: any) {

    let query = event.query;



    let data= {
      search:query
    }


    if (this.AreasControlService.OriginScreen === "BUSINESS-ACTIVITES"){
      this.AreasControlService.getAreasControlByBusiness(data,this.idRegister).subscribe((response)=>{
        this.filteredAreas = response
      });
    }

    else if(this.AreasControlService.OriginScreen === "CUSTOMERS"){
  this.AreasControlService.getAreasByCustomerPest(data,this.idRegister).subscribe((response)=>{
        this.filteredAreas = response
      });
    }

  }

 /**
   * Search Area Categories by Autocomplete
   */
 searchStationType() {
  if (this.dataForm.value.station_type_search !== '') {
    let station_type = this.dataForm.value.station_type.id;
    this.dataForm.get('station_type')?.setValue(station_type);
    this.loadDataStationsControl();
  }
}


  /**
   * In a real application, make a request to a remote url with the query and return filtered results.
   * @param event
   */
  filterTypeStation(event: any) {
    let query = event.query;
    let data= {
      search:query
    }
    this.AreasControlService.getTypeStations(data).subscribe((response)=>{
      this.filteredTypeStations = response
    });

  }

  resetFilter(){
    this.dataForm.patchValue({
      page_size : 10,
      first: 0,
      page: 0,
      rows: 10,
      search: '',
      totalRecords: 0,
      range_date: null,
      initial_date: null,
      final_date: null,
      station_type: '',
      station_type_search: '',
      nesting_area: '',
      nesting_area_search: '',

      job_center: this.UserService.JobCenter.id,
  });
    this.loadDataStationsControl();
  }
}
