<div [formGroup]="dataForm" class="grid formgrid mt-3 pb-4">
  <div class="col-12 mt-2 lg:col-3 lg:mb-0 mb-4">
    <span class="w-100 p-fluid p-input-icon-left">
      <i class="pi pi-search"></i>
      <input
      formControlName="search"
        pInputText
        type="text"
        (input)="getEventValue($event)"
        placeholder="Buscar..."
        class="w-full"
      />
    </span>
  </div>

  <div class="col-12 mt-2 lg:col-3 lg:mb-0 mb-4">
    <span class="w-100 p-fluid p-float-label">
      <p-calendar formControlName="range_date" selectionMode="range" [readonlyInput]="true" appendTo="body" (onSelect)="searchDate()"></p-calendar>
      <label>Rango de fecha</label>
    </span>
  </div>

  <div class="col-12 mt-2 lg:col-3 lg:mb-0 mb-4">
    <span class="w-100 p-fluid p-float-label">
        <p-autoComplete formControlName="zone_search" [showEmptyMessage]="true" [suggestions]="filteredZones" appendTo="body"
        [dropdown]="true" (completeMethod)="filterZone($event)" field="name" [minLength]="1" (onSelect)="searchZone()">
       </p-autoComplete>
       <label>Zona</label>
    </span>
  </div>
  <div class="col-12 mt-2 lg:col-3 lg:mb-0 mb-4">
    <span class="w-100 p-fluid p-float-label">
        <p-autoComplete formControlName="area_category_search" [showEmptyMessage]="true" [suggestions]="filteredCategoryAreas" appendTo="body"
        [dropdown]="true" (completeMethod)="filterCategoryArea($event)" field="name" [minLength]="1" (onSelect)="searchAreaCategory()">
       </p-autoComplete>
        <label>Tipo Area</label>
    </span>


  </div>
  <div
    class="col-12 mt-3 mb-4 d-flex justify-content-end text-center lg:col-6 lg:mb-0 "
  >
  <p-button
  type="button"
  icon="fa-solid fa-arrow-rotate-left"
  styleClass="p-button-rounded p-button-secondary p-button-sm me-2"
  (click)="resetFilter()"

>
</p-button>

    <p-button
    (click)="AddUpdate(null)"
      type="button"
      icon="fa-solid fa-plus"
      styleClass="p-button-rounded"
      [disabled]="loading"
    >
    </p-button>
  </div>
</div>

<div class="table table-responsive custom-table-container">
  <p-table
  class="custom-table"
  styleClass="p-datatable-sm"
  scrollDirection="horizontal"
  #dtCalendar
  [value]="dataItems"
  dataKey="id"
  sortMode="multiple"
  [rows]="10"
  [showCurrentPageReport]="true"
  [rowsPerPageOptions]="[10, 25, 50]"
  [loading]="loading"
  [paginator]="false"
  [globalFilterFields]="['name']"
  >

    <ng-template pTemplate="header">
      <tr>
        <th
          pSortableColumn="name"
          class="white-space-nowrap bg-primary"

        >
          Nombre <p-sortIcon field="name"></p-sortIcon>
        </th>
        <th
        pSortableColumn="area_category.name"
          class="white-space-nowrap bg-primary"

        >
        Tipo
        <p-sortIcon field="area_category.name"></p-sortIcon>
        </th>

        <th
        pSortableColumn="checkPoint"
          class="white-space-nowrap bg-primary"

        >
        Punto de control
        <p-sortIcon field="checkPoint"></p-sortIcon>
        </th>

        <th
        pSortableColumn="certificate"
          class=" bg-primary"

        >
        Certificado
        <p-sortIcon field="certificate"></p-sortIcon>
        </th>
        <th

          class="bg-primary"

        >
        Fecha

        </th>




      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
      <tr class="text-sm">
        <td class=" text-sm">

          <div class="dropdow d">
            <p  class="fw-bold h6 dropdown-toggle text-primary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              {{item.name}} - {{item.area_category.name}}
            </p>
            <ul class="dropdown-menu">
              <li><a (click)="AddUpdate(item)"  class="dropdown-item hov-pointer text-primary fw-bold" ><i class="fas fa-edit me-2"></i>Editar</a></li>
             <li><a (click)="deleteItem(item)" class="dropdown-item hov-pointer text-danger fw-bold" ><i class="fas fa-trash-alt me-2"></i>Eliminar</a></li>
            </ul>
          </div>

        </td>
        <td>
          Zona: {{ item.zone?.name }} <br />
          Tipo Area: {{ item.area_category?.name }}
        </td>
<td class=" text-sm">
  <i
  class="pi"
  [ngClass]="{
    'text-success pi-check-circle': item.check_point,
    'text-danger pi-times-circle': !item.check_point
  }"
></i>
</td>
<td class=" text-sm">
  <i
    class="pi"
    [ngClass]="{
      'text-success pi-check-circle': item.certificate,
      'text-danger pi-times-circle': !item.certificate
    }"
  ></i>
</td>
<td class=" text-sm">
  <i class="fa-solid fa-plus text-primary"></i>
  {{ item.created_at | date : "short" : "GMT-5" }} <br />
  <i class="fa-solid fa-arrows-rotate text-primary"></i>
  {{ item.updated_at | date : "short" : "GMT-5" }}
</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="7">no hay datos.</td>
      </tr>
    </ng-template>
  </p-table>
  <p-paginator
  [first]="dataForm.value.first"
  [rows]="dataForm.value.rows"
  [rowsPerPageOptions]="[10, 20, 30, 50]"
  [totalRecords]="dataForm.value.totalRecords"
  (onPageChange)="onPageChange($event)"
  currentPageReportTemplate="{currentPage} de {totalPages}"
  [showJumpToPageDropdown]="true"
  [showPageLinks]="true"
  [showJumpToPageInput]="true"
></p-paginator> </div>
