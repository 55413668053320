import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { CatalogsEnum } from 'src/app/system/core/enums/catalogs.model';

import { AreasControlService } from 'src/app/system/services/areasControlService';
import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';

@Component({
  selector: 'app-add-update-stations',
  templateUrl: './add-update-stations.component.html',
  styleUrls: ['./add-update-stations.component.scss']
})
export class AddUpdateStationsComponent {
  Form: FormGroup = this.FormBuilder.group({
    id: [''],
    name: ['', Validators.required],
    station_type: ['', Validators.required],
    nesting_area: ['', Validators.required],
    start_range: [false],
    end_range: [false],
    key: ['']
  });
  filteredTypeStations!: any[];
  filteredAreas!: any[];
  isEdit: boolean = false;
  AreaDiagramSelected:any;
  constructor(
    private FormBuilder: FormBuilder,
    private LoadingService: LoadingService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private DialogService: DialogService,

    private MessagesService: MessagesService,
    private router: Router,
    private AreasControlService: AreasControlService,
  ) { }

  ngOnInit() {

    if(this.config.data?.area && !this.config.data.item){
 
   
      this.AreaDiagramSelected = this.config.data?.area;
   
      this.Form.patchValue({
        nesting_area: this.AreaDiagramSelected.id
      });
      
  }

    else{
      if (this.config.data.item) {
        this.isEdit = true;
        if (this.AreasControlService.OriginScreen === "BUSINESS-ACTIVITES"){
          this.getStationsBusinessById();
        }
        if (this.AreasControlService.OriginScreen === "CUSTOMERS"){
          this.getStationsCustomerById();
        }
      }
    }






  }

  getStationsCustomerById() {
    this.AreasControlService.getDataCustomerById(CatalogsEnum.AREA_STATIONS, this.config.data.item.id).subscribe((res) => {

      this.Form.controls["key"].setValidators(Validators.required);
      this.Form.controls["key"].updateValueAndValidity();

      this.Form.controls["start_range"].clearValidators();
      this.Form.controls["start_range"].updateValueAndValidity();

      this.Form.controls["end_range"].clearValidators();
      this.Form.controls["end_range"].updateValueAndValidity();

      this.Form.patchValue({
        id: res.id,
        name: res.name,
        station_type: res.station_type,
        nesting_area: res.nesting_area,
        start_range: res.start_range,
        end_range: res.end_range,
        key: res.key
      });
    })
  }

  getStationsBusinessById() {
    this.AreasControlService.getDataBusinessById(CatalogsEnum.AREA_STATIONS, this.config.data.item.id).subscribe((res) => {

      this.Form.controls["key"].setValidators(Validators.required);
      this.Form.controls["key"].updateValueAndValidity();

      this.Form.controls["start_range"].clearValidators();
      this.Form.controls["start_range"].updateValueAndValidity();

      this.Form.controls["end_range"].clearValidators();
      this.Form.controls["end_range"].updateValueAndValidity();

      this.Form.patchValue({
        id: res.id,
        name: res.name,
        station_type: res.station_type,
        nesting_area: res.nesting_area,
        start_range: res.start_range,
        end_range: res.end_range,
        key: res.key
      });
    })
  }

  cancel() {
    this.ref?.close();
  }

  accept() {
    this.LoadingService.Show();
    if (!this.config.data.item) {
      this.save();
      return
    }
    this.update();
  }

saveStationsCustomer(){
  const { id, name, station_type, nesting_area, start_range, end_range, } = this.Form.value;

  let data = {
    name,
    station_type: station_type.id,
    start_range,
    end_range,
    //id,
    //customer: this.config.data.idRegister,
    nesting_area:nesting_area?.id ?? nesting_area
  }

  this.AreasControlService.addStationCustomerPestControl(data).subscribe(
    (res) => {



      this.LoadingService.Hide();
      this.MessagesService.ShowMessage(
        TYPE_TOAST.SUCCESS,
        'Agregado correctamente',
        ''
      );
      this.ref?.close({ success: true });
    }
  );
}

  saveStationsBussiness(){

    const { id, name, station_type, nesting_area, start_range, end_range, } = this.Form.value;

    let data = {
      name,
      station_type: station_type.id,
      start_range,
      end_range,
      id,
      business_activity: this.config.data.idRegister,
      nesting_area:nesting_area?.id ?? nesting_area
    }

    this.AreasControlService.addStationBusinessPestControl(data).subscribe(
      (res) => {
        console.log(res);


        this.LoadingService.Hide();
        this.MessagesService.ShowMessage(
          TYPE_TOAST.SUCCESS,
          'Agregado correctamente',
          ''
        );
        this.ref?.close({ success: true });
      }
    );
  }

  save() {
    if (this.AreasControlService.OriginScreen === "CUSTOMERS") {
      this.saveStationsCustomer()
    }
    if (this.AreasControlService.OriginScreen === "BUSINESS-ACTIVITES") {
      this.saveStationsBussiness()
    }
  }





  update() {
    if (this.AreasControlService.OriginScreen === "BUSINESS-ACTIVITES") { this.updateStationsBussines() }
    if (this.AreasControlService.OriginScreen === "CUSTOMERS") { this.updateStationsCustomer() }
  }



  updateStationsCustomer(){
    const { id, name, key, station_type, nesting_area, start_range, end_range, } = this.Form.value;

    let data = {
      name,
      station_type: station_type.id,
      key,
      id,
      customer: this.config.data.idRegister,
      nesting_area:nesting_area?.id ?? nesting_area
    }


    this.AreasControlService.putDataCustomerPestcontrol(CatalogsEnum.AREA_STATIONS, id, data).subscribe(
      (res) => {
        this.LoadingService.Hide();
        this.MessagesService.ShowMessage(
          TYPE_TOAST.SUCCESS,
          'Agregado correctamente',
          ''
        );
        this.ref?.close({ success: true });
      }
    );
  }


    updateStationsBussines(){
      const { id, name, key, station_type, nesting_area, start_range, end_range, } = this.Form.value;

      let data = {
        name,
        station_type: station_type.id,
        key,
        id,
        business_activity: this.config.data.idRegister,
        nesting_area:nesting_area?.id ?? nesting_area
      }


      this.AreasControlService.putDataBusinessPestcontrol(CatalogsEnum.AREA_STATIONS, id, data).subscribe(
        (res) => {
          this.LoadingService.Hide();
          this.MessagesService.ShowMessage(
            TYPE_TOAST.SUCCESS,
            'Agregado correctamente',
            ''
          );
          this.ref?.close({ success: true });
        }
      );
    }













  filterTypeStation(event: any) {
    let query = event.query;
    let data = {
      search: query
    }
    this.AreasControlService.getTypeStations(data).subscribe((response) => {
      this.filteredTypeStations = response
    });




  }

  filterArea(event: any) {

    let query = event.query;



    let data = {
      search: query
    }
    if (this.AreasControlService.OriginScreen === "BUSINESS-ACTIVITES"){
      this.AreasControlService.getAreasControlByBusiness(data, this.config.data.idRegister).subscribe((response) => {
        this.filteredAreas = response
      });
    }

    else if(this.AreasControlService.OriginScreen === "CUSTOMERS"){
     this.AreasControlService.getAreasByCustomerPest(data,this.config.data.idRegister).subscribe((response)=>{
        this.filteredAreas = response
      });
    }

  }
}
