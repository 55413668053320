import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { RouterModule } from '@angular/router';


import { DialogErrorComponent } from './components/dialog-error/dialog-error.component';
import { LoadingComponent } from './components/loading/loading.component';
import { ProfileDialogComponent } from './components/profile-dialog/profile-dialog.component';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { PrimengModule } from '../primeng/primeng.module';
import { SelectComponent } from './components/select/select.component';
import { AreasControlComponent } from './components/areas-control/areas-control.component';
import { FormZonesComponent } from './components/form-areas/form-zones/form-zones.component';
import { FormAreasComponent } from './components/form-areas/form-areas/form-areas.component';
import { FormStationsComponent } from './components/form-areas/form-stations/form-stations.component';
import { FormInspectionsComponent } from './components/form-areas/form-inspections/form-inspections.component';
import { TableZonesComponent } from './components/table-zones/table-zones.component';
import { AddUpdateZoneComponent } from './components/table-zones/components/add-update-zone/add-update-zone.component';
import { TableAreasComponent } from './components/table-areas/table-areas.component';
import { AddUpdateAreaComponent } from './components/table-areas/components/add-update-area/add-update-area.component';
import { TableStationsComponent } from './components/table-stations/table-stations.component';
import { AddUpdateStationsComponent } from './components/table-stations/components/add-update-stations/add-update-stations.component';
import { UploadDocumentImageComponent } from './components/upload-document-image/upload-document-image.component';
import { TableCheckPointComponent } from './components/table-check-point/table-check-point.component';
import { TablePerimetersComponent } from './components/table-perimeters/table-perimeters.component';
import { AddUpdatePerimeterComponent } from './components/table-perimeters/components/add-update-perimeter/add-update-perimeter.component';
import { AddUpdateCheckPointComponent } from './components/table-check-point/components/add-update-check-point/add-update-check-point.component';
import { UploadComponent } from './components/upload/upload.component';
import { CarouselImagesComponent } from './components/carousel-images/carousel-images.component';
import { MultipleFilesComponent } from './components/multiple-files/multiple-files.component';

import { NgxDropzoneModule } from 'ngx-dropzone';
import { TreeDiagramComponent } from './components/tree-diagram/tree-diagram.component';
import { OptionsDiagramTreeComponent } from './components/options-diagram-tree/options-diagram-tree.component';

@NgModule({
  declarations: [

    DialogErrorComponent,
    LoadingComponent,
    ProfileDialogComponent,
    UploadFileComponent,
    SelectComponent,
    AreasControlComponent,
    FormZonesComponent,
    FormAreasComponent,
    FormStationsComponent,
    FormInspectionsComponent,
    TableZonesComponent,
    AddUpdateZoneComponent,
    TableAreasComponent,
    AddUpdateAreaComponent,
    TableStationsComponent,
    AddUpdateStationsComponent,
    UploadDocumentImageComponent,
    TableCheckPointComponent,
    TablePerimetersComponent,
    AddUpdatePerimeterComponent,
    AddUpdateCheckPointComponent,
    UploadComponent,
    CarouselImagesComponent,
    MultipleFilesComponent,
    TreeDiagramComponent,
    OptionsDiagramTreeComponent,

  ],
  imports: [
    CommonModule,
   // BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgxDropzoneModule,

    PrimengModule,

  ],
  exports:[
    MultipleFilesComponent,
    LoadingComponent,
    UploadFileComponent,
    SelectComponent,
    AreasControlComponent,
    TableZonesComponent,
    UploadDocumentImageComponent,
    UploadComponent
  ]
})
export class SharedModule { }
