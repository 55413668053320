import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {

@Input() ImageString = "";
@Output()uploadFile = new EventEmitter<File | string>();

  objectURL: string = '';
  textPhoto: string = 'Selecciona o arrastra una imagen';
  image: any;

  randomValue:number =0 ;
  ngOnInit(): void {
    this.randomValue = Math.random();
    let photo = this.ImageString;
    this.image = photo + '?' + this.randomValue;

  }


  onUpload(event: any) {
    let file = event.files[0];


    if (!file.objectURL) {
      return;
    }
    else {
    this.image = file;
      this.objectURL = file.objectURL ?? '';
    //  this.Form.get('photo')?.setValue(file);
      this.uploadFile.emit(file);
    }

  }

  removeImage() {
    this.image = null;
    this.uploadFile.emit("");
 /**
  *
    this.Form.get('photo')?.setValue('');
  */
  }
}
