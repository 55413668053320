export enum TYPE_TOAST{
  ERROR='error',
  SUCCESS='success',
  WARNING ='warn',
  INFO = 'info',
}





