import { Component } from '@angular/core';

@Component({
  selector: 'app-form-areas',
  templateUrl: './form-areas.component.html',
  styleUrls: ['./form-areas.component.scss']
})
export class FormAreasComponent {

}
