import { LocalStorageService } from './../../services/local-storage-service';

import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, of, finalize } from 'rxjs';
import { StorageEnum } from '../enums/StorageEnum';


const C_TOKEN_ADMINISTRADOR = "access_token";

/**
 * INTERCEPTOR PARA LA INYECCION DE TOKENS, CONTROL DE ERRORES Y REINTENTOS DE RECONEXION
 */

@Injectable({
  providedIn: 'root',
})
export class InjectTokenInterceptor implements HttpInterceptor {
  /**
   * Constructor del interceptor
   * @param StorageService parametro para almacenar u obtener datos del local o session storage
   * @param MensajesService parametro para mostrar mensajes toast
   */
  constructor(private LocalStorageService:LocalStorageService) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.LocalStorageService.get(StorageEnum.TOKEN);

    if (token !== null) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }
    return next.handle(req);
  }



}
