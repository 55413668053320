// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //baseUrl: 'https://api.pestwareapp.com/administrative',
  //s3Url: 'https://pestware-storage-prod.s3.amazonaws.com/',

  baseUrl: 'https://api-dev.pestforceapp.com/administrative',
 // baseUrl: 'http://127.0.0.1:8000/administrative',

  StaffLogin:'https://api-dev.pestforceapp.com/staff/',
  s3Url: 'https://pestware-storage-devs.s3.amazonaws.com/',

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
