import { AreasControlService } from '../../../services/areasControlService';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { CatalogService } from 'src/app/system/services/catalogs.service';
import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';
import { UserService } from 'src/app/system/services/user.service';

@Component({
  selector: 'app-areas-control',
  templateUrl: './areas-control.component.html',
  styleUrls: ['./areas-control.component.scss']
})
export class AreasControlComponent {

 index : number = 0;

  Screen_Origin:string ="";
  idRegister:string ="";
  constructor(

    private LoadingService: LoadingService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,

    private AreasControlService:AreasControlService
) {}

ngOnInit() {

this.Screen_Origin = this.AreasControlService.OriginScreen;
this.idRegister = this.config.data.item.id;

}


handleChange(event: any) {
  this.index = event.index;
}

}
