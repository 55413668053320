import { NgModule,LOCALE_ID} from '@angular/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import {  RoutingModule } from './routing.module';
import { AppComponent } from './app.component';

import { DialogService } from 'primeng/dynamicdialog';
import { MessagesService } from './system/services/message.service';
import { MessageService } from 'primeng/api';
import { interceptorProviders } from './system/core/interceptors/interceptors';
import { PrimengModule } from './system/primeng/primeng.module';
import { SharedModule } from './system/shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { AppLayoutModule } from './system/layout/app.layout.module';
//import { DateFnsModule } from 'ngx-date-fns';

@NgModule({
    imports: [//
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppLayoutModule,
        PrimengModule,
        SharedModule,
        RoutingModule
    ],
    declarations: [
        AppComponent,
    ],
    providers: [
        interceptorProviders,
        DialogService,MessagesService,MessageService,

    ],

    bootstrap: [AppComponent],

})
export class AppModule { }
