import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { CatalogsEnum } from 'src/app/system/core/enums/catalogs.model';

import { AreasControlService } from 'src/app/system/services/areasControlService';
import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';

@Component({
  selector: 'app-add-update-area',
  templateUrl: './add-update-area.component.html',
  styleUrls: ['./add-update-area.component.scss']
})
export class AddUpdateAreaComponent {
  Form: FormGroup = this.FormBuilder.group({
        id: [ ''],
        name: [ '',Validators.required],
        zone: [ '',Validators.required],
        area_category: [ '',Validators.required],
        check_point: [false],
        certificate: [ false],
        business_activity: [''],
        customer: [''],
       color : ['#3C5898'],
});

filteredZones!: any[];
filteredCategoryAreas!: any[];
ZoneDiagramSelected:any;
constructor(
    private FormBuilder: FormBuilder,
    private LoadingService: LoadingService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private DialogService: DialogService,

    private MessagesService: MessagesService,
    private router: Router,
    private AreasControlService:AreasControlService,
) {}

ngOnInit() {

 if(this.config.data?.zone && !this.config.data.item){
  this.ZoneDiagramSelected = this.config.data?.zone;
  this.Form.patchValue({
    zone: this.ZoneDiagramSelected.id
  });
  
 }

else{

  if (this.config.data.item) {

    if (this.AreasControlService.OriginScreen === "BUSINESS-ACTIVITES"){
      this.getAreaControlBusinessById();
    }

    if (this.AreasControlService.OriginScreen === "CUSTOMERS"){
      this.getAreaControlCustomersById();
    }

  }
}


}



getAreaControlCustomersById(){

  
  this.AreasControlService.getDataCustomerById(CatalogsEnum.NESTING_AREAS,this.config.data.item.id ).subscribe((res)=>{

    this.Form.patchValue({
      id: res.id,
      name: res.name,
      zone: res.zone,
      area_category: res.area_category,
      check_point: res.check_point,
      certificate: res.certificate,
      customer: res.customer.id,
      color :  res.color,
  });
  })
  }




getAreaControlBusinessById(){
this.AreasControlService.getDataBusinessById(CatalogsEnum.NESTING_AREAS,this.config.data.item.id ).subscribe((res)=>{

  this.Form.patchValue({
    id: res.id,
    name: res.name,
    zone: res.zone,
    area_category: res.area_category,
    check_point: res.check_point,
    certificate: res.certificate,
    business_activity: res.business_activity.id,
   color :  res.color,
});
})
}

cancel() {
    this.ref?.close();
}

accept() {
    this.LoadingService.Show();
   if(!this.config.data.item){
    this.save();
    return
   }
   this.update();
}

save(){
  if (this.AreasControlService.OriginScreen === "CUSTOMERS") {
    this.saveAreaCustomer()
  }
  if (this.AreasControlService.OriginScreen === "BUSINESS-ACTIVITES") {
    this.saveAreaBussiness()
  }

}

update(){
  if (this.AreasControlService.OriginScreen === "CUSTOMERS") {
    this.updateAreaCustomer()
  }
  if (this.AreasControlService.OriginScreen === "BUSINESS-ACTIVITES") {
    this.updateAreaBussiness()
  }

}


updateAreaBussiness(){

  const {area_category,business_activity,certificate,check_point,color,id,name,zone} = this.Form.value;

  let data = {
    name,
    zone:zone?.id ?? zone,
    check_point,
    certificate,
    color,
    business_activity:this.config.data.idRegister,
    area_category:area_category.id
  }


    this.AreasControlService.putDataBusinessPestcontrol(CatalogsEnum.NESTING_AREAS,id,data).subscribe(
        (res) => {
            this.LoadingService.Hide();
            this.MessagesService.ShowMessage(
                TYPE_TOAST.SUCCESS,
                'Agregado correctamente',
                ''
            );
            this.ref?.close({ success: true });
        }
    );
}
updateAreaCustomer(){
  const {area_category,certificate,check_point,color,id,name,zone} = this.Form.value;

  let data = {
    name,
    zone:zone?.id ?? zone,
    check_point,
    certificate,
    color,
    customer:this.config.data.idRegister,
    area_category:area_category.id
  }


    this.AreasControlService.putDataCustomersPestcontrol(CatalogsEnum.NESTING_AREAS,id,data).subscribe(
        (res) => {
            this.LoadingService.Hide();
            this.MessagesService.ShowMessage(
                TYPE_TOAST.SUCCESS,
                'Actualizado correctamente',
                ''
            );
            this.ref?.close({ success: true });
        }
    );
}
saveAreaCustomer(){
  const {area_category,business_activity,certificate,check_point,color,id,name,zone} = this.Form.value;

  let data = {
    name,
    zone:zone?.id ?? zone,
    check_point,
    certificate,
    color,
    customer:this.config.data.idRegister,
    area_category:area_category.id
  }

    this.AreasControlService.addDataCustomersPestcontrol(CatalogsEnum.NESTING_AREAS,data).subscribe(
        (res) => {
            this.LoadingService.Hide();
            this.MessagesService.ShowMessage(
                TYPE_TOAST.SUCCESS,
                'Agregado correctamente',
                ''
            );
            this.ref?.close({ success: true });
        }
    );
}
saveAreaBussiness(){
  const {area_category,certificate,check_point,color,id,name,zone} = this.Form.value;

  let data = {
    name,
    zone:zone?.id ?? zone,
    check_point,
    certificate,
    color,
    business_activity:this.config.data.idRegister,
    area_category:area_category.id
  }

    this.AreasControlService.addDataBusinessPestcontrol(CatalogsEnum.NESTING_AREAS,data).subscribe(
        (res) => {
            this.LoadingService.Hide();
            this.MessagesService.ShowMessage(
                TYPE_TOAST.SUCCESS,
                'Agregado correctamente',
                ''
            );
            this.ref?.close({ success: true });
        }
    );
}

 filterCategoryArea(event: any) {
  let query = event.query;
  let data= {
    search:query
  }
  this.AreasControlService.getCategoryAreas(data).subscribe((response)=>{
    this.filteredCategoryAreas = response
  });




}

filterZone(event: any) {

  let query = event.query;



  let data= {
    search:query
  }

  if (this.AreasControlService.OriginScreen === "BUSINESS-ACTIVITES"){
    this.AreasControlService.getZonesControlByBusiness(data,this.config.data.idRegister).subscribe((response)=>{
      this.filteredZones = response
    });
  }

  if (this.AreasControlService.OriginScreen === "CUSTOMERS"){
    this.AreasControlService.getZonesControlByCustomer(data,this.config.data.idRegister).subscribe((response)=>{
      this.filteredZones = response
    });
  }


}
}
