import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TYPE_TOAST } from 'src/app/system/core/enums/ToastsEnums';
import { CatalogsEnum } from 'src/app/system/core/enums/catalogs.model';
import { AreasControlService } from 'src/app/system/services/areasControlService';
import { LoadingService } from 'src/app/system/services/loading.service';
import { MessagesService } from 'src/app/system/services/message.service';

@Component({
  selector: 'app-add-update-check-point',
  templateUrl: './add-update-check-point.component.html',
  styleUrls: ['./add-update-check-point.component.scss']
})
export class AddUpdateCheckPointComponent {
  Form: FormGroup = this.FormBuilder.group({
    id: [''],
    name: ['', Validators.required],
    perimeter: ['', Validators.required],
    start_range: [false],
    end_range: [false],
    key: ['']
  });

  filteredPerimeters!: any[];
  isEdit: boolean = false;
  constructor(
    private FormBuilder: FormBuilder,
    private LoadingService: LoadingService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private DialogService: DialogService,

    private MessagesService: MessagesService,
    private router: Router,
    private AreasControlService: AreasControlService,
  ) { }

  ngOnInit() {
    if (this.config.data.item) {
      this.isEdit = true;

        this.getCheckPointCustomerById();

  }
}

getCheckPointCustomerById() {
    this.AreasControlService.getDataCheckPoinById(this.config.data.item.id).subscribe((res) => {

      this.Form.controls["key"].setValidators(Validators.required);
      this.Form.controls["key"].updateValueAndValidity();

      this.Form.controls["start_range"].clearValidators();
      this.Form.controls["start_range"].updateValueAndValidity();

      this.Form.controls["end_range"].clearValidators();
      this.Form.controls["end_range"].updateValueAndValidity();

      this.Form.patchValue({
        id: res.id,
        name: res.name,
        perimeter: res.perimeter,
        start_range: res.start_range,
        end_range: res.end_range,
        key: res.key
      });
    })
  }


  cancel() {
    this.ref?.close();
  }

  accept() {
    this.LoadingService.Show();
    if (!this.config.data.item) {
      this.save();
      return
    }
    this.update();
  }




  save() {
    const { id, name,  perimeter, start_range, end_range, key } = this.Form.value;

    let data = {
      name,
      key,
      start_range,
      end_range,
      //id,
      //customer: this.config.data.idRegister,
      perimeter:perimeter.id
    }

    this.AreasControlService.addCheckPoint(data).subscribe(
      (res) => {
        this.LoadingService.Hide();
        this.MessagesService.ShowMessage(
          TYPE_TOAST.SUCCESS,
          'Agregado correctamente',
          ''
        );
        this.ref?.close({ success: true });
      }
    );
  }





  update() {
    const { id, name,  perimeter, start_range, end_range, key } = this.Form.value;

    let data = {
      name,
      key,

      perimeter:perimeter.id
    }


    this.AreasControlService.putDataCheckPointsPestcontrol(CatalogsEnum.AREA_STATIONS, id, data).subscribe(
      (res) => {
        this.LoadingService.Hide();
        this.MessagesService.ShowMessage(
          TYPE_TOAST.SUCCESS,
          'Actualizado correctamente',
          ''
        );
        this.ref?.close({ success: true });
      }
    );
  }



  filterPerimeter(event: any) {

    let query = event.query;



    let data = {
      search: query
    }

     this.AreasControlService.getPerimetersByCustomerPest(data,this.config.data.idRegister).subscribe((response)=>{
        this.filteredPerimeters = response
      });


  }
}
