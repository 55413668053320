import { JobCenter } from './../core/models/employee.interface';
import { UserService } from './user.service';



import {Injectable, OnDestroy, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient, HttpParams} from "@angular/common/http";

import {FormGroup} from "@angular/forms";
import { LocalStorageService } from './local-storage-service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CatalogService {

  private baseUrl: string = environment.baseUrl

  constructor(private http: HttpClient,

    private UserService:UserService,
              private localStorageService: LocalStorageService) { }



getCatalogSelect(urlCatalog:string, filters:any){
  let params = new HttpParams();

  if(this.UserService.JobCenter ){
    params =    params.append('job_center', this.UserService.JobCenter.id) 

  }
 
  if(filters.search){

      params =  params.append('search',  filters.search)
  }
  if(filters.isMain){
    params = params.append('is_main', filters.isMain)
  }
  if(filters.customer){
    params = params.append('customer', filters.customer)
  }
    if(filters.indication){
      params = params.append('indication', filters.indication)
    }
    if(filters.service_type){
      params = params.append('service_type', filters.service_type)
    }
    if(filters.business_activity_icon){
      params = params.append('business_activity_icon', filters.business_activity_icon)
    }
    if(filters.station_type){
      params = params.append('station_type', filters.station_type)
    }
    if(filters.plague_category){
      params = params.append('plague_category', filters.plague_category)
    }
    if(filters.category){
      params = params.append('category', filters.category)
    }

 

  return this.http.get<any>(`${urlCatalog}/`, {params})
}


              getCatalogPlaguesByIdQuote(nameCatalog: string, id: string) : Observable<any> {
                return this.http.get<any>(`${this.baseUrl}/catalogs/${nameCatalog}/?quote=${id}`)
              }
              getCatalogById(nameCatalog: string, id: string) : Observable<any> {
                return this.http.get<any>(`${this.baseUrl}/catalogs/${nameCatalog}/${id}`)
              }


              getCatalog(nameCatalog:string,filters:any){

                let params = new HttpParams();

                params = params.append('job_center', this.UserService.JobCenter.id);

                if(filters.search){

                    params =  params.append('search',  filters.search)
                }

                  if(filters.indication){
                    params = params.append('indication', filters.indication)
                  }
                  if(filters.service_type){
                    params = params.append('service_type', filters.service_type)
                  }
                  if(filters.business_activity_icon){
                    params = params.append('business_activity_icon', filters.business_activity_icon)
                  }
                  if(filters.station_type){
                    params = params.append('station_type', filters.station_type)
                  }
                  if(filters.plague_category){
                    params = params.append('plague_category', filters.plague_category)
                  }
                  if(filters.category){
                    params = params.append('category', filters.category)
                  }

                return this.http.get<any>(`${this.baseUrl}/catalogs/${nameCatalog}/`, {params})
            }

            deleteItemCatalog(nameCatalog:string,idItem:string){
                return this.http.delete<any>(`${this.baseUrl}/catalogs/${nameCatalog}/${idItem}/`)
            }


            addDataCatalog(nameCatalog: string,dataSave:any) : Observable<any> {
                    if(!dataSave.job_center )dataSave.job_center = this.UserService.JobCenter.id;
                    return this.http.post<any>(`${this.baseUrl}/catalogs/${nameCatalog}/`, dataSave)
                  }

                  patchCatalog(nameCatalog: string,idItem:string,dataSave:any) : Observable<any> {

                        return this.http.put<any>(`${this.baseUrl}/catalogs/${nameCatalog}/${idItem}/`, dataSave)
                      }


getCatalogOriginSource(){
    let params = new HttpParams();
    params = params.append('job_center', this.UserService.JobCenter.id);
    return this.http.get<any>(`${this.baseUrl}/catalogs/origin-sources/`, {params})
}

  // Delete Cover
  deleteOriginSource(id: string) : Observable<any> {

    return this.http.delete<any>(`${this.baseUrl}/catalogs/origin-sources/${id}/`)
  }

  addOriginSource(name: string) : Observable<any> {
let data  = {
    name,
    job_center: this.UserService.JobCenter.id
}
    return this.http.post<any>(`${this.baseUrl}/catalogs/origin-sources/`, data)
  }


  updateOriginSource(name: string,id:string) : Observable<any> {
    let data  = {
        name,
        job_center: this.UserService.JobCenter.id
    }
        return this.http.put<any>(`${this.baseUrl}/catalogs/origin-sources/${id}/`, data)
      }



      getCatalogAdministrativeExpense(nameCatalog:string,filters:any){

        let params = new HttpParams();

        params = params.append('job_center', this.UserService.JobCenter.id);

        if(filters.search){

            params =  params.append('search',  filters.search)
        }


          if(filters.indication){
            params = params.append('indication', filters.indication)
          }
          if(filters.service_type){
            params = params.append('service_type', filters.service_type)
          }
          if(filters.business_activity_icon){
            params = params.append('business_activity_icon', filters.business_activity_icon)
          }
          if(filters.station_type){
            params = params.append('station_type', filters.station_type)
          }
          if(filters.plague_category){
            params = params.append('plague_category', filters.plague_category)
          }
          if(filters.category){
            params = params.append('category', filters.category)
          }

        return this.http.get<any>(`${this.baseUrl}/${nameCatalog}/expenses`, {params})
      }

getCatalogAdministrative(nameCatalog:string,filters:any){

  let params = new HttpParams();

  if(this.UserService.JobCenter ){
    params =    params.append('job_center', this.UserService.JobCenter.id) 

  }
 
  if(filters.search){

      params =  params.append('search',  filters.search)
  }
  if(filters.isMain){
    params = params.append('is_main', filters.isMain)
  }
  if(filters.customer){
    params = params.append('customer', filters.customer)
  }
    if(filters.indication){
      params = params.append('indication', filters.indication)
    }
    if(filters.service_type){
      params = params.append('service_type', filters.service_type)
    }
    if(filters.business_activity_icon){
      params = params.append('business_activity_icon', filters.business_activity_icon)
    }
    if(filters.station_type){
      params = params.append('station_type', filters.station_type)
    }
    if(filters.plague_category){
      params = params.append('plague_category', filters.plague_category)
    }
    if(filters.category){
      params = params.append('category', filters.category)
    }

 

  return this.http.get<any>(`${this.baseUrl}/${nameCatalog}/`, {params})
}

getCatalogPestControl(nameCatalog:string,filters:any){

  let params = new HttpParams();

  params = params.append('job_center', this.UserService.JobCenter.id);

  if(filters.search){

      params =  params.append('search',  filters.search)
  }

  if(filters.nesting_area__customer__id){

    params =  params.append('nesting_area__customer__id',  filters.customer)
}


    if(filters.indication){
      params = params.append('indication', filters.indication)
    }
    if(filters.service_type){
      params = params.append('service_type', filters.service_type)
    }
    if(filters.business_activity_icon){
      params = params.append('business_activity_icon', filters.business_activity_icon)
    }
    if(filters.station_type){
      params = params.append('station_type', filters.station_type)
    }
    if(filters.plague_category){
      params = params.append('plague_category', filters.plague_category)
    }
    if(filters.customer){
      params = params.append('customer', filters.customer)
    }

  return this.http.get<any>(`${this.baseUrl}/pest-control/${nameCatalog}/`, {params})
}
getCatalogInventories(nameCatalog:string,filters:any){

  let params = new HttpParams();

  params = params.append('job_center', this.UserService.JobCenter.id);

  if(filters.search){

      params =  params.append('search',  filters.search)
  }
  if(filters.warehouse){

    params =  params.append('warehouse',  filters.warehouse)
}
if(filters.customer){

  params =  params.append('customer',  filters.customer)
}

  return this.http.get<any>(`${this.baseUrl}/inventories/${nameCatalog}/`, {params})
}

getCatalogGeneric(route:string,filters:any){

  let params = new HttpParams();

  params = params.append('job_center', this.UserService.JobCenter.id);

  if(filters.search){

      params =  params.append('search',  filters.search)
  }

  if(filters.nesting_area__customer__id){

    params =  params.append('nesting_area__customer__id',  filters.customer)
}


    if(filters.indication){
      params = params.append('indication', filters.indication)
    }
    if(filters.service_type){
      params = params.append('service_type', filters.service_type)
    }
    if(filters.business_activity_icon){
      params = params.append('business_activity_icon', filters.business_activity_icon)
    }
    if(filters.station_type){
      params = params.append('station_type', filters.station_type)
    }
    if(filters.plague_category){
      params = params.append('plague_category', filters.plague_category)
    }
    if(filters.customer){
      params = params.append('customer', filters.customer)
    }
console.log(`${this.baseUrl}/${route}/`);

  return this.http.get<any>(`${this.baseUrl}/${route}/`, {params})
}
}
